import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import ArticleGrid from "../components/ArticleGrid";
import FilterCategory from "../components/FilterCategory";
import EmailUsBlock from "../components/EmailUsBlock";
import Seo from "../components/seo.jsx";
import ogIMage from "../images/og.png";
const TagCategory = ({ data, location }) => {
    // const tagMetaTitle = data.PageData.blogs.map((el) => el.metaTitle);

    // const tagMetaDescription = data.PageData.blogs.map((el) => el.metaDescription);
    return (
        <Layout>
            <Seo
                title={data.PageData.metaTitle}
                description={data.PageData.metaDescription}
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
            />
            <div className="tag-category">
                <div className="container container--first-v2">
                    <div className="tag-category__text">
                        <p className="text-p3 text-fw-medium text-clr-primary gap-05x">Tagged as</p>
                        <h1 className="text-h1 text-clr-primary text-fw-medium">
                            {data.PageData.categoryType}
                        </h1>
                    </div>
                    <FilterCategory
                        uniqueCategory={data.allContentfulBlogTagCategory.nodes}
                        icon
                        tags
                    />
                    <ArticleGrid featuredArticles={data.PageData.blogs} />
                </div>
            </div>
            <section className="email">
                <div className="container container--last-v2">
                    <EmailUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default TagCategory;

export const query = graphql`
    query BlogTagPageQuery($id: String) {
        PageData: contentfulBlogTagCategory(id: { eq: $id }) {
            id
            categoryType
            slug
            metaTitle
            metaDescription
            blogs {
                id
                title
                slug
                readTime
                author
                blogImage {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                }
                categories {
                    categoryType
                    slug
                }
            }
        }
        allContentfulBlogTagCategory {
            nodes {
                categoryType
                slug
            }
        }
    }
`;
